import React from "react"
import { Helmet } from "react-helmet";
import {graphql, useStaticQuery} from "gatsby";

const SEO = () => {
    const { site } = useStaticQuery(query)

    const title = site.siteMetadata.pagetitle ? `${site.siteMetadata.pagetitle} | ${site.siteMetadata.title}` : site.siteMetadata.title
    const description = site.siteMetadata.pagedesc || site.siteMetadata.description
    const url = site.siteMetadata.pagepath ? `${site.siteMetadata.siteUrl}${site.siteMetadata.pagepath}` : site.siteMetadata.siteUrl
    const imgurl = site.siteMetadata.pageimg ? `${site.siteMetadata.siteUrl}${site.siteMetadata.pageimg}` : site.siteMetadata.blogimg || `${site.siteMetadata.siteUrl}/thumb.jpg`
    const imgw = site.siteMetadata.pageimgw || 1280
    const imgh = site.siteMetadata.pageimgh || 640

    return (
        <Helmet>
            <html lang={ site.siteMetadata.lang } />
            <title>{ title }</title>
            <meta name="description" content={ description } />

            <link rel="canonical" href={ url } />

            <meta property="og:site_name" content={title} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />

            <meta property="og:type" content="website" />

            <meta property="og:locale" content={site.siteMetadata.locale} />
            <meta property="fb:app_id" content={site.siteMetadata.fbappid} />

            <meta property="og:image" content={imgurl}/>
            <meta property="og:image:width" content={imgw}/>
            <meta property="og:image:height" content={imgh}/>
        </Helmet>
    )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        lang
        description
        siteUrl
        locale
        fbappid
      }
    }
  }
`

export default SEO

// export default props => {
//     const data = useStaticQuery(graphql`
//         query{
//             site {
//                 siteMetadata {
//                     title
//                     lang
//                     description
//                     siteUrl
//                     locale
//                     fbappid
//                 }
//             }
//         }
//     `)
//     const title = props.pagetitle ? `${props.pagetitle} | ${data.site.siteMetadata.title}` : data.site.siteMetadata.title
//     const description = props.pagedesc || data.site.siteMetadata.description
//     const url = props.pagepath ? `${data.site.siteMetadata.siteUrl}${props.pagepath}` : data.site.siteMetadata.siteUrl
//     const imgurl = props.pageimg ? `${data.site.siteMetadata.siteUrl}${props.pageimg}` : props.blogimg || `${data.site.siteMetadata.siteUrl}/thumb.jpg`
//     const imgw = props.pageimgw || 1280
//     const imgh = props.pageimgh || 640
//
//     return(
//         <Helmet>
//             <html lang={ data.site.siteMetadata.lang } />
//             <title>{ title }</title>
//             <meta name="description" content={ description } />
//
//             <link rel="canonical" href={ url } />
//
//             <meta property="og:site_name" content={data.site.siteMetadata.title} />
//             <meta property="og:title" content={title} />
//             <meta property="og:description" content={description} />
//             <meta property="og:url" content={url} />
//
//             <meta property="og:type" content="website" />
//
//             <meta property="og:locale" content={data.site.siteMetadata.locale} />
//             <meta property="fb:app_id" content={data.site.siteMetadata.fbappid} />
//
//             <meta property="og:image" content={imgurl}/>
//             <meta property="og:image:width" content={imgw}/>
//             <meta property="og:image:height" content={imgh}/>
//         </Helmet>
//     )
// }