import React from "react";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faTwitter,
    faFacebookSquare,
    faInstagram,
} from "@fortawesome/free-brands-svg-icons"

const footer = () => {
    return(
        <footer className="footer">
            <div className="container">
                <div className="site">
                    <Link to={`/`}>
                        <h1 className="footer-logo">
                            <StaticImage src="../images/logo-white.png" alt="ZEROSTEP KUMAMOTO BASKETBALL" />
                        </h1>
                        <p>熊本のバスケットシーンをお届けするWEBメディア</p>
                    </Link>
                    <ul className="sns" style={{paddingTop: "20px"}}>
                        <li>
                            <a href="https://twitter.com/0step_kumamoto/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faTwitter} />
                                <span className="sr-only">Twitter</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/0step.kumamoto.bb/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faFacebookSquare} />
                                <span className="sr-only">Facebook</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/0step_kumamoto_bb/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faInstagram} />
                                <span className="sr-only">Instagram</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}
export default footer;