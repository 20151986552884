import React from "react";
import { Link } from "gatsby"
import Menu from "../components/menu";
import { StaticImage } from "gatsby-plugin-image";

const header = () => (
    <header className="header">
        <Menu id="stack" width={250} right stack/>
        <div className="container">
            <div className="site">
                <Link to={`/`}>
                    <h1 className="header-logo">
                        <StaticImage src="../images/header-logo.png" alt="ZEROSTEP KUMAMOTO BASKETBALL" />
                    </h1>
                </Link>
            </div>
        </div>
    </header>
)
export default header;